@import 'bulma/bulma.sass';

@keyframes flashBgRed {
  0% {
    background-color: $white;
  }
  50% {
    background-color: lighten($red, 25%);
  }
  100% {
    background-color: $white;
  }
}

.flash {
  animation: flashBgRed 1s normal forwards ease-in-out !important;
}
