@import 'bulma/bulma.sass';

.dispatch-panel {
  display: flex;
  flex-direction: column;
  padding: 52px 0 0 0;
  background: $white;
  width: 400px;
  transition: width 0.2s;
  color: $black;
  overflow-y: hidden;

  &-title {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: left;
    align-items: center;
    margin-bottom: 0.5em;
    box-shadow: 0px 4px 4px 0px darken($white, 10%);
    border-right: 1px solid darken($white, 10%);

    h2.subtitle {
      width: 100%;
      padding: 0.5rem 0 0.5rem 1rem;
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
    }
  }

  &-hide {
    .button {
      background: none;
      outline: none;
      border: none;

      i {
        font-size: 1.5rem;
        color: $blue;
      }

      &:hover {
        i {
          color: $black;
        }
      }
    }
  }

  &-body {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &-section {
    display: flex;
    flex-direction: column;

    &-header {
      background: $blue;
      color: $white;
      width: 100%;
      font-size: 0.85rem;
      font-weight: 800;
      padding: 0.15rem 0.15rem 0.15rem 1rem;
    }

    &-body {
      padding: 1rem 1rem 1rem 1rem;
      display: flex;
      flex-direction: column;
    }
  }

  .columns {
    flex-direction: column;
    margin: 0 !important;

    .column {
      padding: 0;
      margin-top: 0.5em;
    }
  }

  nav.level {
    .level-item {
      margin-right: 0.25rem !important;
    }
  }

  .box {
    border-radius: 0;
    margin: 0 1em 0 1em;
  }

  .button {
    border-radius: 0;
    padding: 0.5em;
    height: 2em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-right: 0.5em;
    font-size: 0.85em;

    &.is-primary {
      background: darken($green, 5%);
      padding: 1em;

      &:hover {
        background: darken($green, 10%);
      }
    }
  }

  .checkbox {
    font-size: 0.9rem;
    margin-top: 0.25rem;
  }

  .field {
    .control {
      .icon {
        height: 2em;
      }
    }

    .label.has-text-info {
      color: darken($grey, 10%) !important;
      font-size: 0.85rem;
    }

    .value {
      font-size: 0.9em;
    }

    .input {
      height: 2em;
      border-radius: 0;
      padding: 0.5em;
      font-size: 0.9rem;
      line-height: 1;

      &::placeholder {
        color: darken($white, 20%);
      }
    }

    .file {
      border-radius: 0;
      input[type='file'] {
        height: 2em;
        padding: 0em;
      }
    }

    .select {
      height: 2em;

      select {
        height: 2em;
        background: none;
        border-radius: 0;
        color: $black;
        font-size: 0.9rem;
        padding: 0 0 0 0.5rem;

        option {
          color: $black;
        }
      }
    }

    .textarea {
      border-radius: 0;
      background: none;
      color: $black;
      min-height: 4em;
      font-size: 0.9em;
    }
  }

  .card {
    border-radius: 0;

    .card-content {
      padding: 1rem;
    }
  }
}
