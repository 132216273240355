@import 'bulma/bulma.sass';

.dispatch-sidebar-container {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  transition: left 0.1s;
  filter: drop-shadow(1px 1px 1px darken($white, 25%));
}

.dispatch-sidebar-chevron {
  position: relative;
  top: 140px;
  left: 0;
  padding: 0;
  margin: 0;
  height: 48px;

  .button {
    padding: 0;
    margin: 0;
    font-size: 12px;
    width: 16px;
    height: 48px;
    appearance: none;
    outline: none;
    border: none;
    border-radius: 0 !important;
    background: $white;
    color: $blue !important;
    box-shadow: none !important;

    &:hover {
      background: darken($white, 5%);
    }

    &:focus {
      outline: none !important;
    }
  }
}

.dispatch-notifications {
  position: absolute;
  left: 16px;
  bottom: 16px;
  min-width: 600px;
  padding: 1rem;
  transition: left 0.2s;
}
