@import 'bulma/bulma.sass';

.dispatch-sidebar {
  width: 60px;
  height: 100%;
  background: #2e409e;
  display: flex;
  flex-direction: column;
  padding: 5rem 0 0 0;

  &-header {
    padding-bottom: 1rem;
  }

  &-expand {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0 0 0 1rem;

    .button {
      background: none;
      outline: none;
      border: none;
      box-shadow: none !important;
      font-size: 1.5rem;
      padding: 0;

      span {
        color: lighten($blue, 15%);
        font-size: 1rem;
        padding-left: 1rem;
        font-weight: 800;
      }

      i {
        color: lighten($blue, 15%);
        width: 28px;
        height: 28px;
      }

      &:hover {
        i {
          color: lighten($blue, 25%);
        }
      }
    }
  }

  &.expanded {
    width: 220px;

    & > .dispatch-sidebar-item {
      span {
        display: flex;
      }
    }
  }

  &-connection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 0 0 1rem 0;
    padding: 0 0 0 1rem;
    color: $white;
    font-weight: 800;

    i {
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }

    &.connected {
      i {
        color: $green;
      }
    }

    &.disconnected {
      i {
        color: $red;
      }
    }
  }

  &-item {
    width: 100%;
    height: 40px;
    color: $white;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin: 0;
    padding: 0 0 0 1rem;

    &.active {
      background: lighten($blue, 15%);
    }

    &:hover {
      background: lighten($blue, 10%);
    }

    &.subdued {
      color: lighten($blue, 15%);

      &.active {
        color: lighten($blue, 50%);
      }

      &:hover {
        color: lighten($blue, 50%);
      }
    }

    span {
      margin-left: 1rem;
      display: none;
      overflow: hidden;
      height: 28px;
      align-items: center;
      font-weight: 800;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;

      i {
        font-size: 1.5rem;
      }
    }
  }
}
