.chat-addons {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  display: flex;
  align-items: center;
}

.create-chat-channel {
  font-size: 1.8rem;
  border-radius: 9999px;
  color: #dbdbdb;
  background-color: #485fc7;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    i {
      color: white;
    }
  }
}

.chat-external {
  font-size: 2rem;
  margin-left: 1rem;
}

.chat-header-title {
  padding: 5px 7px;
  display: flex;
  align-items: center;
}

.chat-header-pound {
  color: #006cff;
  font-weight: 800;
  padding-right: 2px;
}

.chat-header-addons {
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.chat-edit-button {
  cursor: pointer;

  i {
    color: #006cff;
    font-size: 1.25rem;

    &::before {
      font-family: 'Font Awesome 5 Free' !important;
    }
  }

  &:hover {
    i {
      color: lighten(#006cff, 15%);
    }
  }
}

.chat-attachments {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  align-items: center;
  background-color: white;
  height: 100%;
}

.chat-attachment {
  display: flex;
  flex-direction: row;
  width: 80%;
  padding: 2rem;
  margin: 0.5rem;
  border: 1px solid darken(white, 15%);

  .media {
    width: 150px;
    overflow: hidden;

    i {
      font-size: 3rem;

      &::before {
        font-family: 'Font Awesome 5 Free' !important;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    font-size: 0.8rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;
    max-width: 300px;
  }

  .chat-img {
    width: 150px;
  }

  .chat-video {
    width: 150px;
  }
}

.str-chat {
  width: 100%;
}

.str-chat__channel-header {
  position: relative;
  border-bottom: 1px solid #485fc7;
}

.str-chat__channel-list-react {
  min-width: 250px;
  max-width: 250px;
}
