@import 'bulma/bulma.sass';

.navbar {
  transition: background-color 0.5s;

  .navbar-item {
    transition: background-color 0.5s, color 0.5s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .icon > i {
      font-size: 1.2rem;
      margin-right: 0.5rem;
      line-height: 1;
    }
  }

  .navbar-burger {
    display: block !important;
  }
}

.nav-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 500px;
  height: 100vh;
  background-color: $white;
  padding: 1rem;
  flex-direction: column;
  display: none;
  color: $black;

  &.is-active {
    display: flex;
  }
}

.nav-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
  font-size: 0.85rem;
  width: 100%;
}

.nav-logo {
  width: 85px;
  height: 85px;
}

.nav-brand {
  padding-left: 2rem;
}

.nav-title {
  font-weight: 400;
  font-size: 2rem;
  line-height: 1;
}

.nav-menu-close {
  position: absolute;
  top: 1rem;
  right: 0rem;

  .button {
    background: none;
    outline: none;
    border: none;

    i {
      font-size: 1.5rem;
      color: $blue;
    }

    &:hover {
      i {
        color: $black;
      }
    }
  }
}

.nav-user-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-setting {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid $grey;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: darken($white, 5%);
    }
  }
}

.nav-setting-icon {
  width: 75px;

  i {
    font-size: 1.5rem;
  }
}

.nav-setting-label {
  width: 250px;
  display: flex;
  align-items: center;
}

.nav-setting-value {
  display: flex;
  align-items: center;
  width: 100%;
}

.map-layer-buttons {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  gap: 1rem;
}

.map-layer-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 16px;

  &:hover,
  &.active {
    outline: 4px solid $blue;
  }

  .image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
  }
}
