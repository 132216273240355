.user-list {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: scroll;
  flex-shrink: 0;
  border: 1px solid #dbdbdb;
}

.user {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  min-height: 60px;
  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  cursor: pointer;

  &:hover {
    background-color: darken(white, 5%);
  }
}

.name {
  font-weight: 600;
  font-size: 0.95rem;
  margin-left: 1rem;
}

.checkmark {
  padding: 6px;
  margin-left: auto;
  margin-right: 1rem;
  border-radius: 9999px;

  i {
    display: none;
    color: white;
    font-size: 0.6rem;

    &::before {
      font-family: 'Font Awesome 5 Free' !important;
    }
  }

  &.selected {
    background-color: #62b2ec;
    i {
      display: block;
    }
  }
}
