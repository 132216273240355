.hero-body,
.section {
  padding: 1.25rem 1.25rem;
}

.title {
  font-size: 1.5em;
  font-weight: 200;
}

.level:not(:last-child) {
  margin-bottom: 1em;
}

.table td {
  padding: 0.35em 0.851em;
}

.modal-card-head {
  height: 30px;
}

.modal-card-title {
  font-size: 1rem;
}

.notifications {
  position: fixed;
  bottom: 16px;
  left: 16px;
}
