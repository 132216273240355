.actions {
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #c4c4c4;
  padding: 1rem;
}

.user-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    margin-right: 1rem;
  }

  .timestamp {
    margin-left: auto;
    gap: 8px;
  }
}

.open-chat {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 9999px;
  background: #c4c4c4;
  padding-left: 1rem;
  width: 100%;
  height: 50px;
  gap: 8px;
  cursor: pointer;
  font-size: 1.25rem;

  &:hover {
    background: lighten(#c4c4c4, 15%);
  }
}
