@import 'bulma/bulma.sass';

.selected-incident {
  position: relative;
  left: 0;
  top: 55px;
  color: #ffa500;
  font-weight: 600;
  font-size: 1rem;
  width: auto;
}

.dispatch-incident-marker {
  &-label {
    position: relative;
    bottom: 1px;
  }
}

.dispatch-device-marker {
  &-label {
    -webkit-text-stroke: 1px $black;
    position: relative;
    bottom: 22px;
  }
}

.dispatch-incident-map-marker {
  &-label {
    -webkit-text-stroke: 1px $black;
    position: relative;
    bottom: 20px;
  }
}

.draggable-map-marker {
  position: absolute;
  top: 8rem;
  right: 1rem;
  padding: 0.5rem;
  background-color: $white;
  font-size: 1.25rem;
  color: red;
  filter: drop-shadow(1px 1px 1px darken($white, 25%));

  i {
    cursor: grab;

    &:hover {
      color: darken(red, 15%);
    }
  }
}

.delete-marker-btn {
  cursor: pointer;

  &:hover {
    color: darken($white, 25%);
  }
}
