.switch {
  position: relative;
  background: #c4c4c4;
  border-radius: 15px;
  width: 59px;
  height: 24px;
  cursor: pointer;
}

.switch.loading {
  opacity: 0.5;
  cursor: default;
}

.switch.active {
  background: #3fc083;
}

.label {
  position: relative;
  left: 28px;
  top: 2px;
  color: #fafbfb;
  font-size: 14px;
  font-weight: 600;
  transition: left 0.2s ease-in;
  text-transform: uppercase;
}

.switch.active > .label {
  left: 8px;
}

.circle {
  position: absolute;
  left: 4px;
  top: 4px;
  width: 16px;
  height: 16px;
  background: #fafbfb;
  border-radius: 9999px;
  transition: left 0.2s ease-in;
}

.switch.active > .circle {
  left: 37px;
}
