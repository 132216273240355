@import 'bulma/bulma.sass';

.card {
  &.active {
    background-color: #ffbd0d;
  }
}

.incident-card {
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .card-footer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.add-incident-btn {
  position: sticky;
  bottom: 1rem;
  right: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  filter: drop-shadow(2px 2px 2px darken($white, 50%));
}
