@import 'bulma/bulma.sass';

.location-select {
  display: flex;
  flex-direction: row;

  div:not(:last-child) {
    border-right: 1px solid darken($white, 10%);
  }
}

.location-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 0.85rem;
  width: 100%;

  i {
    font-size: 1.25rem;
  }

  div {
    margin-left: 0.5rem;
  }

  &:hover {
    background-color: darken($white, 5%);
  }
}

.location-address {
  font-weight: 800;
  line-height: 1.1;
  margin-top: 1rem;
}

.location-latlng {
  color: darken($white, 25%);
  margin-top: 0.5rem;
  font-size: 1rem;
}

.location-menu {
  display: flex;
  flex-direction: row;
}

.map-warning {
  margin-top: 1em;
  color: $blue;
  font-size: 0.9em;

  span {
    padding-left: 0.5em;
  }

  button {
    appearance: none;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1em;
    text-decoration: underline;
    color: $blue;
    padding: 0;

    &:hover {
      text-decoration: none;
    }
  }
}

.dispatch-type-select {
  &-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.5rem;

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.25rem;

      &-icon {
        border-radius: 9999px;
        padding: 0.35rem;
        background: $white;
        filter: drop-shadow(1px 2px 2px $grey);

        i {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      span {
        margin-left: 0.5rem;
      }
    }

    &-body {
      margin-left: 2.5rem;

      div {
        display: flex;
        flex-direction: column;

        label.checkbox:last-child:not(:only-child) {
          margin-left: 1rem;
        }
      }
    }

    &:hover {
      i::before {
        opacity: 1;
      }
    }
  }
}
