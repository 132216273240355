.button {
  border-radius: 9999px !important;
  font-weight: 600;
  min-height: 37px;

  &.is-link {
    background-color: #2e409e !important;

    &:hover {
      background-color: lighten(#2e409e, 10%) !important;
    }
  }

  &.is-disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #808080 !important;
    &:hover {
      background-color: #808080 !important;
    }
  }
}
